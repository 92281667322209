import React from 'react'
import "./qualitypolicy.css"
import NavBar from '../Head/NavBar'
import QualityPolicyPriciples from '../QualityPolicyPrinciples/QualityPolicyPriciples'
import Contacts from '../Contacts/Contacts'

const QualityPolicy = () => {


  const ourmissionvision = [
    {
      name:"Our Mission",
      title:"Tech Solutions Leader",
      description:"To be recognised globally as an organisation providing high-quality technology and design solutions."
    },
    {
      name:"Our Vision",
      title:"We Strive To",
      description:"Empowering Businesses with Design and Technology by developing global tech products that can significantly impact lives."
    },
  ]


  const OurVisionMission = () => {
    return(
      <div className='missionvision-container container'>
        {
          ourmissionvision.map((item) => (
            <div className='missionvision-card'>
              <span>{item.name}</span>
              <div className='h1-container'>
                <h1>{item.title}</h1>
                <div></div>
              </div>
              <p>{item.description}</p>
            </div>
          ))
          
        }
      </div>
    )
  }

  return (
    <>
    <div className='quality-policy-container-wrapper'>
    <NavBar />
    
    <div className='quality-policy-container'>
        <div className='quality-policy-content section-title container'>
          <span>About us</span>
          <div className='h1-container'>
            <h1>We Are Tudo</h1>
            <div></div>
          </div>
            <p>
            Tudo technologies is a leading provider of cutting-edge technologies and services, offering scalable solutions for companies of all sizes. Founded as a product company by a group of friends who started by scribbling their ideas on a piece of paper, today we offer smart, innovative services to dozens of clients worldwide. 
            </p>
        </div>
        
        <div className='quality-policy-img'>
          <img src='/assets/img/quality-policy.png'></img>
        </div>


        <OurVisionMission/>
        <QualityPolicyPriciples></QualityPolicyPriciples>        
    </div>
    <Contacts/>

    

    {/* <div className="quality-policy-gradient">
      <img src="/assets/img/service-gradient.png" alt="" />
    </div>


    <div className="policy-priciple-gradient">
        <img src="/assets/img/service-gradient.png" alt="" />
    </div>

    <div className="policy-priciple-gradient2">
        <img src="/assets/img/service-gradient.png" alt="" />
    </div> */}
    </div>
    

    </>
  )
}

export default QualityPolicy