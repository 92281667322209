import React from 'react'
import "./qualitypolicyprinciple.css"
import Contacts from '../Contacts/Contacts'

const QualityPolicyPriciples = () => {


    const priciples = [
        {
            id:1,
            name:"Customer Focus",
            description:"We prioritize understanding and fulfilling the needs of our customers. By actively listening to their feedback and adapting our processes accordingly, we strive to build lasting relationships based on trust and satisfaction."
        },
        {
            id:2,
            name:"Continual Improvement",
            description:"We are committed to continuously improving our products, processes, and services. Through regular review and refinement, we seek to enhance efficiency, effectiveness, and overall customer value."
        },
        {
            id:3,
            name:"Product Delivery Standard",
            description:"We uphold a strong commitment to Release Quality Assurance, as outlined in all our Statements of Work, ensuring: Zero Critical Issues."
        },
        {
            id:4,
            name:"Compliance",
            description:"We adhere to all applicable legal and regulatory requirements, as well as industry standards and best practices. Our goal is to ensure that our products and services consistently meet the highest standards of quality and safety."
        },
        {
            id:5,
            name:"Timely Delivery of Service",
            description:"We are committed to delivering on our promises. Guided by the core purpose of our organization, we prioritize quality and reliability in every aspect of our delivery process. Our focus remains on implementing effective processes to ensure that products and services are delivered on time, within budget, and with the highest standards of quality."
        },
        {
            id:6,
            name:"Leadership Commitment",
            description:"Our senior management team is fully committed to the implementation and continual improvement of our quality management system. They provide the necessary resources, support, and leadership to ensure that quality remains at the forefront of everything we do."
        },
    ]

  return (
    <>
    <div className='QualityPolicyPriciples-container container'>
        <div className='section-title'>
            <h3>Our quality policy is guided by the following principles</h3>
            <p>
            With our Mission of Empowering Business with Design and Technology, by developing global tech products that can substantially impact life. We are dedicated to delivering products and services that consistently meet or exceed customer requirements. Our commitment to quality is ingrained in every aspect of our operations, from initial design through to production, delivery and customer support.
            </p>
        </div>


        <div className='principle-list-container'>
            {
                priciples.map((item) => (
                    <div className='priciple-list'>
                        <h2>{item.name}</h2>
                        <div className='principle-id-container'>
                            <div className='principle-id'>
                                <p>{item.id}</p>
                            </div>
                        </div>
                        <p className='priciple-description'>{item.description}</p>
                    </div>
                ))
            }
        </div>

        <div className='resp-principle-list-container'>
            {
                priciples.map((item) => (
                    <div className='priciple-list'>
                        <div className='principle-id-container'>
                            <div className='principle-id'>
                                <p>{item.id}</p>
                            </div>
                        </div>
                        <div className='resp-priciple-data'>
                            <h2>{item.name}</h2>
                            <p className='priciple-description'>{item.description}</p>
                        </div>
                        
                    </div>
                ))
            }
        </div>

        <div className='priciple-para'>
            <p>Through the effective implementation of our quality policy, we strive to achieve our overarching goal of total customer satisfaction and sustained business success.
            </p>
        </div>


        <div className='foundername-container'>
            <h1>Arjun G N</h1>
            <p>CEO (Founding Member)<br/>19th August 2024</p>
        </div>


    </div>
    </>
  )
}

export default QualityPolicyPriciples