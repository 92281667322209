import React from 'react'
import "./holidayList.css"

const HolidayList = () => {
  return (
    <>
    <div className='HolidayList'>
        {/* <iframe src="https://tudotechnologies.s3.ap-south-1.amazonaws.com/Tudo+Technologies+2024+Holiday+Calendar.pdf" frameborder="0"></iframe> */}
        <iframe src="/assets/holidaylist2025.pdf" frameborder="0"></iframe>
    </div>
    </>
  )
}

export default HolidayList