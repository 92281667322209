import React, { useEffect } from "react";
import "./App.css";
import "animate.css/animate.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import { Helmet } from "react-helmet";
import OurWorks from "./Components/OurWorks/OurWorks";
// import UseScrolleffect from "./CustomHooks/UseScrolleffect";
import loadjs from "loadjs";
import IndividualWorks from "./Components/IndividualWorks/IndividualWorks";
import Footer from "./Components/Footer/Footer";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import HolidayList from "./Components/HolidayList/HolidayList";
import OurWorksv2 from "./Components/OurWorksv2/OurWorksv2";
import QualityPolicy from "./Components/QualityPolicy/QualityPolicy";


const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

function App() {
	//   UseScrolleffect();

	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<Home />
				</Route>
				<Route exact path='/ourworksv2'>
					<OurWorksv2 />
				</Route>
				{/* <Route exact path='/ourworks'>
					<OurWorks />
				</Route> */}
				<Route exact path='/ourworks/:id'>
					<IndividualWorks />
				</Route>
				<Route exact path='/holidayList'>
					<HolidayList/>
				</Route>
				<Route exact path='/aboutus'>
					<QualityPolicy/>
				</Route>
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;
